
.select-area-layout {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #77b9fa;
	padding: 35px 130px;
	.select-box {
		width: 100%;
		height: 100%;
		border-radius: 25px;
		background-image: url('../../assets/images/selectBack.png');
		background-size: 100%;
		background-position: center;
		.header {
			height: 135px;
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
			background-color: rgba(0, 64, 240, 0.5);
			display: flex;
			justify-content: space-between;
		}
		.left {
			height: 100%;
			color: #fff;
			padding: 28px 0px 0 47px;
			p:first-of-type {
				font-size: 36px;
				margin-bottom: 5px;
			}
			p:last-of-type {
				font-size: 21px;
			}
		}
		.right {
			display: flex;
			color: #fff;
			font-weight: 500;
			align-items: center;
			padding-right: 40px;
			.time {
				width: 160px;
				line-height: 30px;
				font-size: 21px;
				margin-right: 15px;
			}
			.info {
				display: flex;
			}
			.avatar {
				border-radius: 50%;
				height: 90px;
				width: 90px;
			}
			.user-info {
				font-size: 25px;
				margin-left: 15px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				line-height: 35px;
			}
			.logOut {
				cursor: pointer;
			}
			.logOut:hover {
				color: rgb(240, 83, 83);
			}
		}
		.content {
			height: calc(100% - 100px);
			border-bottom-left-radius: 25px;
			border-bottom-right-radius: 25px;
			background-color: rgba(88, 163, 247, 0.1);
		}
		.enter-system {
			height: 17%;
			width: calc(80% + 20px);
			margin: 0 auto;
			padding-right: 30px;
			padding-bottom: 10px;
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-end;

			/deep/ .el-button--text {
				color: #fff;
				font-size: 24px;
			}
		}
		.content-box {
			height: 83%;
			display: flex;
			justify-content: center;
			// align-items: center;
			.outside {
				width: 40%;
				height: 78%;
				border-radius: 10px;
				padding: 10px;
				cursor: pointer;
				background-color: rgba(255, 255, 255, 0.4);
			}
			.outside + .outside {
				margin-left: 20px;
			}
			.img-box {
				width: 100%;
				height: 100%;
				border-radius: 10px;
				background-color: #fff;
				.title {
					height: 110px;
					line-height: 110px;
					text-align: center;
					color: #1c81c9;
					font-size: 36px;
					font-weight: 700;
					font-family: '微软雅黑 Bold', '微软雅黑';
				}
				.subtitle {
					font-size: 20px;
					font-weight: bold;
				}
				.box {
					height: calc(~'100% - 110px');
					text-align: center;
				}
				.box1 {
					img {
						width: 80%;
						height: 90%;
					}
				}
				.box2 {
					img {
						height: 90%;
						width: 85%;
					}
				}
			}
		}
		@media screen and (max-width: 1366px) {
			.title {
				height: 50px !important;
				line-height: 50px !important;
				font-size: 20px !important;
			}
			.box {
				height: calc(~'100% - 50px') !important;
			}
			.box1 {
				img {
					width: 80% !important;
					height: 90% !important;
				}
			}
			.subtitle {
				font-size: 16px !important;
				font-weight: bold;
			}
			.box2 {
				img {
					width: 92% !important;
					height: 95% !important;
				}
			}
			.imgs-box {
				height: 120px !important;
				div {
					font-size: 20px !important;
				}
				svg {
					font-size: 30px !important;
				}
			}
		}
		.jump-box {
			width: 70%;
			// height: 80%;
			margin: 0 auto;
			display: flex;
			padding-top: 60px;
		}
		.return {
			height: 25%;
			width: 70%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;
			margin: 0 auto;
			.el-button--text {
				font-size: 24px !important;
				color: #fff !important;
			}
		}
		.btn-box {
			flex: 1;
			cursor: pointer;
			img {
				width: 100%;
			}
		}
		.btn-box + .btn-box {
			margin-left: 40px;
		}
		.w50 {
			width: 45% !important;
		}
		.imgs-box {
			padding: 10px;
			border-radius: 10px;
			background-color: rgba(255, 255, 255, 0.4);
			height: 140px;
			div {
				height: 100%;
				border-radius: 10px;
				background-color: #33cc99;
				color: #fff;
				font-size: 28px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			svg {
				font-size: 40px;
				margin-right: 15px;
			}
		}
		.sp-img {
			div {
				background-color: #0078f0 !important;
			}
		}
	}
	.echart-box {
		width: 100%;
		height: 100%;
	}
}
